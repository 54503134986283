import { Component, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';

import { timer } from 'rxjs'
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-offset-your-expenses',
  templateUrl: './offset-your-expenses.component.html',
  styleUrls: ['./offset-your-expenses.component.scss']
})
export class OffsetYourExpensesComponent implements OnInit, AfterViewInit {
  @Output() action: EventEmitter<void>;

  personalInformationProgress: number = 0;
  incomeProgress: number = 0;
  deductionsProgress: number = 0;
  creditsProgress: number = 0;

  shouldAllowSubmitButton: boolean = false;

  constructor() {
    this.action = new EventEmitter<void>();
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    timer(0, 100).pipe(takeWhile(val => this.isWidthWithinLimit(this.personalInformationProgress))).subscribe(() => {
      this.personalInformationProgress = this.personalInformationProgress + 4;
    })

    timer(0, 100).pipe(takeWhile(val => this.isWidthWithinLimit(this.incomeProgress))).subscribe(() => {
      this.incomeProgress = this.incomeProgress + 10;
    })

    timer(0, 100).pipe(takeWhile(val => this.isWidthWithinLimit(this.deductionsProgress))).subscribe(() => {
      this.deductionsProgress = this.deductionsProgress + 4;
    })

    timer(0, 100).pipe(takeWhile(val => this.isWidthWithinLimit(this.creditsProgress))).subscribe(() => {
      this.creditsProgress = this.creditsProgress + 2;
    })
  }

  submit(): void {
    this.action.next();
  }

  isWidthWithinLimit(param) {
    if (param === 100) {
      return false;
    }
    else {
      if (this.creditsProgress > 95 && this.deductionsProgress > 95 && this.incomeProgress > 95 && this.personalInformationProgress > 95) {
        this.shouldAllowSubmitButton = true;
      }

      return true;
    }
  }
}
