<ng-container [ngSwitch]="state">
  <app-residency-offline *ngSwitchCase="status.Resident" (action)="onActionEvent()"></app-residency-offline>
  <app-turbo-tax-offline *ngSwitchCase="status.TurbotaxSupportedVisas" (action)="onActionEvent()">
  </app-turbo-tax-offline>
  <app-taxback-offline *ngSwitchCase="status.TaxbackSupportedVisas" (action)="onActionEvent()"></app-taxback-offline>
  <app-residency-offline *ngSwitchCase="status.PersonalDetails" (action)="onActionEvent()"></app-residency-offline>
  <app-not-supported-visa-offline *ngSwitchCase="status.NotSupportedVisas" (action)="onActionEvent()">
  </app-not-supported-visa-offline>
  <app-married-filing *ngSwitchCase="status.MarriedFiling" (action)="onActionEvent()"></app-married-filing>
  <app-no-us-income *ngSwitchCase="status.NoUsIncomeStatusCode" (action)="onActionEvent()"></app-no-us-income>
  <app-different-service-type *ngSwitchCase="status.DifferentServiceTypeStatusCode" (action)="onActionEvent()"></app-different-service-type>
  
  <!-- Canada TDS -->
  <app-sin-number-offline *ngSwitchCase="status.SinNumber" (action)="onActionEvent()"></app-sin-number-offline>
  <app-province-of-employment-ofline *ngSwitchCase="status.ProvinceOfEmployment" (action)="onActionEvent()"></app-province-of-employment-ofline>
</ng-container>