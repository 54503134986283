<div class="non-residency-offline">
    <div class="box">
        <div class="modal-header justify-content-center py-4">
            <p class="heading d-flex align-items-center text-white m-0 font-weight-bold">
                <i class="icon-check3 text-green mr-2"></i>Success
            </p>
        </div>
        <div class="body">
            <h4>Step 2 is now complete. Good job!</h4>
            <p>Click next to proceed with completing information about your expected income in
                {{taxYear}}</p>
            <button (click)="submit()" class="btn btn-primary">Next</button>
        </div>
    </div>
</div>