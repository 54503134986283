<mat-drawer-container>
  <mat-drawer #drawer class="stacy" mode="side" [ngClass]="{'large': !isDefaultStacy}">
    <a class="exit" (click)="drawer.close(); toggleAvatar()">&times;</a>
    <video #stacy autoplay="" webkit-playsinline="" playsinline="" class="video--stacy">
      <p>
        Your browser doesn't support HTML5 video.
      </p>
    </video>
    <div *ngIf="isDefaultStacy" class="message">
      What information might you to have available to you when preparing your
      tax return
    </div>
    <div *ngIf="!isDefaultStacy" class="position-absolute" style="right: 30px;">
      <div *ngIf="stacy.instantMessage !== '' && !shouldShowDocument" class="message">{{activeMessage}}</div>
      <div *ngIf="shouldShowDocument" class="{{documentContainerClass}}">

        <!-- <img src="{{imageSource}}" alt=""> -->
        <mdb-image-modal [modalImages]="lightBoxImages" type="no-margin" class="{{imageContainerClass}}">
        </mdb-image-modal>

      </div>
    </div>
    <button *ngIf="showNavBtn" class="btn btn-ghost ml_90 mt_15 pt_5 pb_5" (click)="onNavigationMessageButtonClicked()"
      style="position: absolute; top: 155px;">
      {{navigationButtonMessage}}
    </button>
    <ul *ngIf="isDefaultStacy" class="tips">
      <li><span>Passport</span></li>
      <li><span>US Entry</span> and <span>Exit Dates</span> for current and all past visits to the US</li>
      <li>
        <span>All income forms</span>: <span>Final payslips</span>, <span>W2</span> forms, <span>1042</span>-s and/or
        <span>1099</span>
      </li>
      <li>
        <span>Visa/Immigration Status information</span>, including <span>Form DS-2019</span> (for J visa holders) or
        <span>Form I-20</span> (for F visa holders)
      </li>
      <li>
        <span>Social Security Number (SSN)</span> or <span>
          Individual Taxpayer Identification Number
          (ITIN)
        </span>.<br><br>If you do not have an ITIN, Sprintax can help you get one!
      </li>
      <li>
        If you are using Sprintax for State Tax Returns(s) preparation only, you will need <span>a copy</span> of your
        already prepared <span>Federal Tax Return</span> (for the current and previous years, if you filed any).
      </li>
    </ul>
  </mat-drawer>

  <aside class="example-sidenav-content avatar" *ngIf="displayAvatar">
    <a (click)="drawer.toggle(); toggleAvatar()">
      <img src="../../../assets/img/avatar-stacy.png" alt="stacy">
      Stacy
    </a>
  </aside>

</mat-drawer-container>