<div class="non-residency-offline">
    <div class="box">
        <div class="modal-header justify-content-center py-4">
            <p class="heading d-flex align-items-center text-white m-0 font-weight-bold">
                <i class="icon-check3 text-green mr-2"></i>Success
            </p>
        </div>
        <div class="body">
            <h4>Congratulations, you completed the process!</h4>
            <p>In the next step you can review all the information you have provided us with so far. Please make sure
                all the information up to this point is correct.</p>
            <button (click)="submit()" class="btn btn-primary">Next</button>
        </div>
    </div>
</div>