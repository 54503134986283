import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BaseHttpService } from 'src/app/services/base-services/base-http.service';
import { EnvironmentStoreService } from '../../other-services/store/environment-store.service';
import { HttpErrorsService } from '../../other-services/http-error/http-errors.service';
import { TokenService } from 'src/app/services/shared-services/other-services/token/token.service';

import { CreateAccount } from './models/create-account.model';
import { Login } from './models/login.model';
import { ResendEmail } from './models/resend-email.model';
import { SentPasswordResetLink } from './models/send-password-reset-link.model';
import { ResetPassword } from './models/reset-password.model';
import { EditProfile } from './models/edit-profile.model';
import { EditAccount } from './models/edit-account.model';

import { TokenModel } from 'src/app/services/shared-services/other-services/token/models/token.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseHttpService {
  constructor(httpErrorsService: HttpErrorsService, environmentStore: EnvironmentStoreService, private httpClient: HttpClient, private tokenService: TokenService, private router: Router) {
    super(httpErrorsService, environmentStore);
  }

  createAccount(model: CreateAccount): Observable<any> {
    return this.httpClient.post<any>(`${this.getBaseUrl()}api/v${this.getApiVersion()}/2021/Account`, model, { headers: this.headers })
  }

  login(model: Login): Observable<TokenModel> {
    return this.httpClient.post<TokenModel>(`${this.getBaseUrl()}api/v${this.getApiVersion()}/2021/Account/Login`, model, { headers: this.headers })
  }

  resendEmail(model: ResendEmail): Observable<string> {
    return this.httpClient.post<string>(`${this.getBaseUrl()}api/v${this.getApiVersion()}/2021/Account/ResendEmail`, model, { headers: this.headers });
  }

  confirmEmail(userId: string, code: string): Observable<void> {
    let httpParams = new HttpParams().set('userId', userId).set('code', code);

    return this.httpClient.post<void>(`${this.getBaseUrl()}api/v${this.getApiVersion()}/2021/Account/ConfirmEmail`, null, { params: httpParams })
  }

  sendPasswordResetLink(model: SentPasswordResetLink): Observable<void> {
    return this.httpClient.post<void>(`${this.getBaseUrl()}api/v${this.getApiVersion()}/2021/Account/SendPasswordResetLink`, model, { headers: this.headers });
  }

  resetPassword(model: ResetPassword): Observable<void> {
    return this.httpClient.post<void>(`${this.getBaseUrl()}api/v${this.getApiVersion()}/2021/Account/ResetPassword`, model, { headers: this.headers });
  }

  /* Methods required authorization */

  logout(): Observable<void> {
    return this.httpClient.post<void>(`${this.getBaseUrl()}api/v${this.getApiVersion()}/2021/Account/logout`, null).pipe(tap(() => {
      document.body.classList.remove('dark', 'light');

      this.tokenService.removeToken();

      this.environmentStore.resetEnvironmentSettings();
      window.location.href = this.environmentStore.getLocalServerUrl();
      /* this.router.navigate(['/']); */
    }));
  }

  getProfile(): Observable<EditProfile> {
    return this.httpClient.get<EditProfile>(`${this.getBaseUrl()}api/v${this.getApiVersion()}/2024/Account`);
  }

  updateProfile(model: EditAccount): Observable<void> {
    return this.httpClient.put<void>(`${this.getBaseUrl()}api/v${this.getApiVersion()}/2021/Account`, model);
  }
}
