import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

// Custom modules
import { AppRoutingModule } from './modules/app-routing/app-routing/app-routing.module';
import { DirectivesModule } from './modules/directives/directives.module';
import { PipesModule } from './modules/pipes/pipes.module';

// 3rd party modules
import { MDBBootstrapModulesPro, MDB_DATE_OPTIONS } from 'ng-uikit-pro-standard';
import { NgxMaskModule } from 'ngx-mask';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { MatIconModule } from '@angular/material/icon';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/home-components/header/header.component';
import { LoginComponent } from './components/home-components/login/login.component';
import { RegisterComponent } from './components/home-components/register/register.component';
import { MainComponent } from './components/home-components/main/main.component';
import { FooterComponent } from './components/home-components/footer/footer.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { EntryDialogComponent } from './components/home-components/entry-dialog/entry-dialog.component';
import { ForgotPasswordDialogComponent } from './components/home-components/forgot-password-dialog/forgot-password-dialog.component';
import { ResetPasswordDialogComponent } from './components/home-components/reset-password-dialog/reset-password-dialog.component';
import { EmailConfirmationDialogComponent } from './components/home-components/email-confirmation-dialog/email-confirmation-dialog.component';
import { ConfigDialogComponent } from './components/home-components/config-dialog/config-dialog.component';
import { ServerErrorsComponent } from './components/server-errors/server-errors.component';

import { JwtInterceptor } from './services/helpers-services/interceptors/jwt.interceptor';
import { StatusCodeHandlerInterceptor } from './services/helpers-services/interceptors/status-code-handler.interceptor';

// Loading spinner
import { LoaderInterceptor } from './services/helpers-services/interceptors/loader.interceptor';
import { LoaderService } from './services/shared-services/other-services/loading-spinner/loader.service';

// localization module import
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './modules/user/modules/shared/shared.module';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';

// loader module
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    dateInputFormat: 'YYYY-MM-DD',
    value: null,
    useUtc: false
  });
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    HomeComponent,
    PageNotFoundComponent,
    LoginComponent,
    RegisterComponent,
    EntryDialogComponent,
    ForgotPasswordDialogComponent,
    ResetPasswordDialogComponent,
    EmailConfirmationDialogComponent,
    ConfigDialogComponent,
    ServerErrorsComponent,
    LoadingSpinnerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModulesPro.forRoot(),
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    DirectivesModule,
    PipesModule,
    MatIconModule,
    LoadingBarHttpClientModule,
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: StatusCodeHandlerInterceptor, multi: true },
    { provide: MDB_DATE_OPTIONS, useValue: { closeAfterSelect: true, minYear: 1900 } },
    LoaderService, { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    TranslateService
  ],
  exports: [
    TranslateModule
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    EntryDialogComponent,
    ForgotPasswordDialogComponent,
    ResetPasswordDialogComponent,
    EmailConfirmationDialogComponent,
    ConfigDialogComponent
  ]
})
export class AppModule { }
