<div class="residency-offline">
  <div class="box">
    <div class="modal-header justify-content-center py-4">
      <p class="heading d-flex align-items-center text-white m-0 font-weight-bold">
        <i class="icon-warning text-green mr-2"></i>Check again
      </p>
    </div>
    <div class="body">
      <p>Sorry, the Sprintax TDS software is designed for people working/studding in Canada.</p>
      <p>You can continue with your registration after you enter Canada.
      </p>
      <button class="btn btn-primary" (click)="submit()">Back</button>
    </div>
  </div>
</div>