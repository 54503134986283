export const LOCAL_STORAGE_TOKEN_KEY = 'token';

export const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const LETTERS_PATTERN = '^[a-zA-Z- ]+$';
export const PHONE_PATTERN = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
export const NUMBERS_PATTERN = /^[0-9]*$/;
export const DECIMALS_PATTERN = /^\d*\.?\d*$/;
export const DECIMALS_PATTERN_2 = /^[0-9]*\.?[0-9]+$/;
export const IDENT_NUMBER_PATTERN = /^[0-9-]*$/;

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';

//Modals
export const ENTRY_MODAL_SIZE = '980px';
export const ADD_NEW_PHONE_MODAL_TITLE = 'Add new phone';
export const EDIT_PHONE_MODAL_TITLE = 'Edit phone';
export const ADD_NEW_ADDRESS_MODAL_TITLE = 'Add new address';
export const EDIT_ADDRESS_MODAL_TITLE = 'Edit address';

export const FIELD_DEFINITION_COUNTRY_CHOICE = 'passport_issued_by';
export const FIELD_DEFINITION_OCCUPATIONTYPE_CHOICE = 'OccupationType';

export const ADULTS_YEAR_LIMIT = 18;

export const FieldDefinitionCountryChoice = 'passport_issued_by';
export const FieldDefinitionOccupationTypeChoice = 'OccupationType';

export const MIN_YEAR = 1930;

export const DEFAULT_THEME = 'light';
export const THEME_KEY = 'theme';

export const STACY_NAV_BUTTON_MORE = 'More';
export const STACY_NAV_BUTTON_PREV = 'Previous'

export const BOOLEAN_YES = 'YES';
export const BOOLEAN_NO = 'NO';

export const EMPLOYER_IN_MASK = '00-0000000';
