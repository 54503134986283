export enum OfflinePageTypes {
  NonResident = 0,
  Resident = 1,
  TurbotaxSupportedVisas = 2,
  TaxbackSupportedVisas = 3,
  PersonalDetails = 4,
  NotSupportedVisas = 5,
  MarriedFiling = 6,
  NoUsIncomeStatusCode = 10,
  DifferentServiceTypeStatusCode = 11,

  /* Canada TDS */
  StateMassachusettsReviewYourAnswersOptionOne = 7,
  StateMassachusettsReviewYourAnswersOptionTwo = 8,
  StateMassachusettsReviewYourAnswersOptionThree = 9,
  SinNumber = 12,
  ProvinceOfEmployment = 13,
}
