<div class="non-residency-offline">
  <div class="box">
    <p class="stacy"><img src="../../../../../../assets/img/avatar-stacy2.png" alt="stacy"></p>
    <p>Your federal tax return is complete. Great job! </p>
    <br />
    <div *ngIf="!shouldAllowSubmitButton" class="mb_20">
      <h5>Transfering data</h5>
      <span>Personal information</span>
      <app-file-progress [progress]="personalInformationProgress" class="mb_5 d-block"></app-file-progress>
      <span>Income</span>
      <app-file-progress [progress]="incomeProgress" class="mb_5 d-block"></app-file-progress>
      <span>Deductions</span>
      <app-file-progress [progress]="deductionsProgress" class="mb_5 d-block"></app-file-progress>
      <span>Credits</span>
      <app-file-progress [progress]="creditsProgress"></app-file-progress>
    </div>
    <p *ngIf="shouldAllowSubmitButton">
      We've successfully transferred your federal information,
      so a big part of your state return is already done.
    </p>
    <p *ngIf="shouldAllowSubmitButton">Now it's time to proceed to your state taxes</p>
    <button (click)="submit()" class="btn btn-primary" [class.disabled]="!shouldAllowSubmitButton">Next</button>
  </div>
</div>