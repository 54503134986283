<div class="residency-offline">
  <div class="box">
    <div class="modal-header justify-content-center py-4">
      <p class="heading d-flex align-items-center text-white m-0 font-weight-bold">
        <i class="icon-warning text-green mr-2"></i>Check again
      </p>
    </div>
    <div class="body">
      <p>Sorry, you need a SIN to complete the Sprintax TDS Canada process.</p>
      <p>You should apply with Service Canada for a Canadian SIN. After you have your SIN you can continue the process.
      </p>
      <button class="btn btn-primary" (click)="submit()">Back</button>
    </div>
  </div>
</div>