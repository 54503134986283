<div class="md-form">
    <input mdbInput type="text" class="example-full-width md-textarea form-control" id="applicationVersion" [(ngModel)]="currentVersion" disabled>
    <label for="applicationVersion">Application version</label>
</div>
<div class="md-form">
    <input mdbInput type="text" class="example-full-width md-textarea form-control" id="baseURL" [(ngModel)]="baseURLProtocol">
    <label for="baseURL">Base URL protocol</label>
</div>
<div class="md-form">
    <input mdbInput type="text" class="example-full-width md-textarea form-control" id="baseURL" [(ngModel)]="baseURL">
    <label for="baseURL">Base URL</label>
</div>
<div class="md-form">
    <input mdbInput type="text" class="example-full-width md-textarea form-control" id="port" [(ngModel)]="baseURLPort">
    <label for="port">Port</label>
</div>
<div class="md-form">
    <input mdbInput type="text" class="example-full-width md-textarea form-control" id="apiVersion" [(ngModel)]="baseApiVersion">
    <label for="apiVersion">Api version</label>
</div>
<div class="form-field submit">
  <button #submitButton class="btn btn-primary" type="submit" (click)="onSaveChangesButtonClicked()">Save changes</button>
</div>
