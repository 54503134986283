import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseHttpService } from 'src/app/services/base-services/base-http.service';
import { EnvironmentStoreService } from 'src/app/services/shared-services/other-services/store/environment-store.service';
import { HttpErrorsService } from 'src/app/services/shared-services/other-services/http-error/http-errors.service';

import { QuestionStoreService } from 'src/app/services/user-services/other-services/question-store.service';
import { StatePack } from '../../steps/review-documents/models/state-pack.model';

@Injectable({
  providedIn: 'root'
})
export class PdfService extends BaseHttpService {

  constructor(httpErrorsService: HttpErrorsService, environmentStore: EnvironmentStoreService, private httpClient: HttpClient, private questionsStore: QuestionStoreService,private router: Router) {
    super(httpErrorsService, environmentStore);
  }

  getAllFormsPDFs(): Observable<any> {
    return this.httpClient.post<any>(`${this.getBaseUrl()}api/v${this.getApiVersion()}/${this.getSelectedYear()}/Federal/ClientPdfFile`, null, { headers: this.setTaxYearHeaders(), responseType: 'blob' as 'json' });
  }

  getAllTdsFormsPDFs(): Observable<any> {
    return this.httpClient.post<any>(`${this.getBaseUrl()}api/v${this.getApiVersion()}/${this.getSelectedYear()}/Pdf/Packs/Download`, null, { headers: this.setTaxYearHeaders(), responseType: 'blob' as 'json' });
  }
  //  Use=ing in Dashboard Page
  getCurrentTdsFormPDF(url: string): Observable<any> {
    return this.httpClient.post<any>(this.getBaseUrl() + url.substring(1), null, { headers: this.setClickedTaxYearHeaders(), responseType: 'blob' as 'json'});
  }

  // Using in Finish Page
  getCurrentSelectedTdsFormPDF(url: string): Observable<any> {
    return this.httpClient.post<any>(this.getBaseUrl() + url.substring(1), null, { headers: this.setTaxYearHeaders(), responseType: 'blob' as 'json'});
  }

   // Using in Summary Page
   getSummatyPDF(): Observable<any> {
    return this.httpClient.post<any>(`${this.getBaseUrl()}api/v${this.getApiVersion()}/${this.getSelectedYear()}/Pdf/Packs/Download/Summary`, {name: 'DownloadSummaryPdf'}, { headers: this.setTaxYearHeaders(), responseType: 'blob' as 'json'});
  }  

  getAvailablePacks(): Observable<StatePack[]> {
    return this.httpClient.get<StatePack[]>(`${this.getBaseUrl()}api/v${this.getApiVersion()}/${this.getSelectedYear()}/Available/Packs`, { headers: this.setTaxYearHeaders() });
  }

  getStatePdf(pathValue: string): Observable<any> {
    return this.httpClient.post<any>(`${this.getBaseUrl()}${pathValue}`, null, { headers: this.setTaxYearHeaders(), responseType: 'blob' as 'json' });
  }

  private getSelectedYear(): number {
    return this.questionsStore.getSelectedYear();
  }

  private setTaxYearHeaders(): HttpHeaders {
    let taxYear: any = this.questionsStore.getSelectedYear();
    
    if (taxYear !== null && taxYear !== undefined) {
      taxYear = taxYear.toString();
    }
    else {
      this.router.navigate(['/', 'user', 'taxreturns']);

      return;
    }

    return new HttpHeaders({
      'accept': 'application/file',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      'taxYear': taxYear
    });
  }

  private setClickedTaxYearHeaders(): HttpHeaders {
    let taxYear: any = this.questionsStore.getClickedYear();

    if (taxYear !== null && taxYear !== undefined) {
      taxYear = taxYear.toString();
    }
    else {
      this.router.navigate(['/', 'user', 'taxreturns']);

      return;
    }

    return new HttpHeaders({
      'accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      'taxYear': taxYear
    });
  }
}
