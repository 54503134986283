<div class="residency-offline">
    <div class="box">
        <div class="modal-header justify-content-center py-4">
            <p class="heading d-flex align-items-center text-white m-0 font-weight-bold">
                <i class="icon-check3 text-green mr-2"></i>Success
            </p>
        </div>
        <div class="body">
            <p>Based on the information provided, you are considered a Resident for tax purposes in Canada, which means
                the Sprintax
                Canada TDS software is suitable for you.
            </p>
            <button (click)="submit()" class="btn btn-primary">Next</button>
        </div>
    </div>
</div>