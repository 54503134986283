import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';

import { AuthService } from 'src/app/services/shared-services/other-services/auth/auth.service';
import { AccountService } from 'src/app/services/shared-services/http-services/account/account.service';

import { ResetPasswordDialogComponent } from '../home-components/reset-password-dialog/reset-password-dialog.component';
import { EmailConfirmationDialogComponent } from '../home-components/email-confirmation-dialog/email-confirmation-dialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  // Parameter passed to reset password dialog component
  public code: string;
  public userId: string;

  private subscription: Subscription

  constructor(private router: Router, private authenticationService: AuthService, private route: ActivatedRoute, private dialog: MatDialog, private accountService: AccountService) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    if (this.authenticationService.isAuthenticated() && !this.authenticationService.isAdmin()) {
      this.router.navigate(['user', 'taxreturns']);
    }
   
    this.route.queryParams.subscribe(
      (queryParams) => {
        this.userId = queryParams['userId'];
        this.code = queryParams['code'];
      }
    );

    // Reset password dialog
    if (this.route.snapshot.queryParams['action'] === 'reset') {
      if (this.userId !== '' && this.userId !== undefined && this.userId !== null && this.code !== null && this.code !== undefined && this.code !== '') {
        this.code = this.code.replace(/=/g, '%3d').replace(/[/]/g, '%2f').replace(/[+]/g, '%2b').replace(/[ ]/g, '%2b');

        this.dialog.closeAll();

        this.dialog.open(ResetPasswordDialogComponent, {
          width: '980px',
          data: { code: this.code, userId: this.userId },
        });
      }
    }

    // Email confirmation dialogs
    if (this.route.snapshot.queryParams['action'] === 'confirmation') {
      if (this.userId !== '' && this.userId !== undefined && this.userId !== null && this.code !== null && this.code !== undefined && this.code !== '') {
        this.code = this.code.replace(/=/g, '%3d').replace(/[/]/g, '%2f').replace(/[+]/g, '%2b').replace(/[ ]/g, '%2b');

        this.subscription.add(this.accountService.confirmEmail(this.userId, this.code)
          .subscribe(() => {
            this.dialog.closeAll();

            this.dialog.open(EmailConfirmationDialogComponent, {
              data: { shouldShowSuccess: true, email: null },
              panelClass: 'succesPanel'
            });
          }, (errorResponse: HttpErrorResponse) => {
            this.accountService.handleHttpError(errorResponse);
            let email = '';
            if (errorResponse.status === 400) {
              email = errorResponse.error.email;
            }

            this.dialog.closeAll();

            this.dialog.open(EmailConfirmationDialogComponent, {
              data: { shouldShowSuccess: false, email: email },
              panelClass: 'errorPanel'
            });
          }));
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
