<div class="residency-offline">
    <div class="box">
        <p class="stacy"><img src="../../../../../../assets/img/avatar-stacy2.png" alt="stacy"></p>
        <p>Whoops....</p>
        <br>
        <p>If you were not a student, teacher, or trainee temporarily present in the United States on an F, J, M or Q type visa, and you did not have any US income, you do not need to file US tax returns.</p>
        <br>
        <p>By pressing "Close", you'll leave Sprintax and will receive further information about our offline service. By pressing "Back", you can edit any information you've entered so far.</p>
        <button class="btn btn-primary" (click)="submit()">Back</button>
        <button class="btn btn-primary" (click)="submit()">Close</button>
    </div>
</div>