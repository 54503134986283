export enum FinishStateTypes {
  MessageForResidencyFinishWithResident = 1,
  MessageForResidencyFinishWithNonResident = 2,
  MessageForResidencyFinishWithEmigrant = 3,
  MessageForResidencyFinishWithImmigrant = 4,
  GettingToKnowYou = 5,
  LetsTalkMoney = 6,
  OffsetYourExpenses = 7,
  StateTaxes = 8,
  StateFinished = 9
}
