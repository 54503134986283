import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, of } from "rxjs";

import { DetailedQuestionCategoryModel } from "../http-services/questions/models/groups-models/detailed-question-category-model";
import { ResponseUserAnswer } from "src/app/services/user-services/http-services/questions/models/answers-models/response-user-answer";
import { StacyMessagesModel } from "src/app/modules/user/modules/shared/components/stacy/models/stacy-messages.model";
import { QuestionsCollection } from "../http-services/questions/models/questions-collection.model";
import { CompletedQuestion } from "../http-services/questions/models/completed-question.model";
import { ClearNextCategories } from "../http-services/questions/models/clear-next-categories.model";
import { ComplexQuestion } from "src/app/modules/user/modules/questions-framework/components/question-builder/models/question-models/complex-question.model";
import { PopulatedQuestion } from "../http-services/questions/models/answers-models/populated-question.model";

@Injectable({
  providedIn: "root"
})
export class QuestionStoreService {
  questions: QuestionsCollection;
  questionsNavigation: DetailedQuestionCategoryModel[];
  answers: ComplexQuestion[] = [];

  //TODO: remove when final screens are dynamic
  shouldUnlockFinalReviewScreen$ = new BehaviorSubject<boolean>(false);
  shouldUnlockFinalDocumentsScreen$ = new BehaviorSubject<boolean>(false);
  componentName$ = new BehaviorSubject<string>(null);

  onPlaymentOnly$ = new BehaviorSubject<boolean>(false)
  
  shouldFetchAnswers$ = new BehaviorSubject<boolean>(true);
  shouldDisableSubmitButton$ = new BehaviorSubject<boolean>(false);
  cast$ = new BehaviorSubject<CompletedQuestion[]>([]);
  isQ200False$ = new BehaviorSubject<boolean>(false);
  isOtherCourseTypeSelected$ = new BehaviorSubject<boolean>(false);
  castCategories$ = new BehaviorSubject<
    Observable<DetailedQuestionCategoryModel[]>
    >(of([]));
  castStacy$ = new BehaviorSubject<StacyMessagesModel>(
    new StacyMessagesModel("", "", 0)
  );
  castActiveGroup$ = new BehaviorSubject<{
    name: string;
    isStarted: boolean;
    isFinished: boolean;
  }>(null);

  private _displayedGroup: number;
  private _language: string;
  private _selectedYear: number;
  private _clickedYear: number;
  private _areAnswerLoaded: boolean;
  private _lastQuestionNavigationState: {
    categoryNumber: number;
    groupId: number;
    questionId: string;
  };
  private _isInEditMode: boolean;

  constructor() {
    this._language = "en-US";

    this._lastQuestionNavigationState = {
      categoryNumber: null,
      groupId: null,
      questionId: null
    };

    this._areAnswerLoaded = false;
    
  }

  getLastQuestionNavigationState(): {
    categoryNumber: number;
    groupId: number;
    questionId: string;
  } {
    return this._lastQuestionNavigationState;
  }

  setLastQuestionNavigationState(questionId: string): void {
    if (questionId !== null) {
      let question = this.getQuestionsBySelectedLanguage().find(
        question => question.id === questionId
      );

      if (question !== null && question !== undefined) {
        this._lastQuestionNavigationState.categoryNumber = question.category.group;
        this._lastQuestionNavigationState.groupId = question.category.id;
        this._lastQuestionNavigationState.questionId = question.id;
      }
    }
    else {
      this._lastQuestionNavigationState = {
        categoryNumber: null,
        groupId: null,
        questionId: null
      };
    }
  }

  onDocumentFieldFocus(docType: string, focusedField: string): void {
    this.castStacy$.next(new StacyMessagesModel(null, null, null, { docType: docType, field: focusedField }));
  }

  getEditModeStatus(): boolean {
    return this._isInEditMode;
  }

  setEditMode(status: boolean): void {
    this._isInEditMode = status;
  }

  getLanguage(): string {
    return this._language;
  }

  setLanguage(language: string): void {
    this._language = language;
  }

  getSelectedYear(): number {
    return this._selectedYear;
  }

  setSelectedYear(taxYear: number): void {
    this._selectedYear = taxYear;
  }

  getClickedYear(): number {
    return this._clickedYear;
  }

  setClickedYear(taxYear: number): void {
    this._clickedYear = taxYear;
  }

  setQuestionCollection(questionCollection: QuestionsCollection): void {
    this.questions = questionCollection;
  }

  getQuestionsBySelectedLanguage(): ComplexQuestion[] {
    if (this.questions !== null && this.questions !== undefined) {
      return this.questions[this._language.toString()];
    }
    return null;
  }

  setQuestionNavigation(categories: DetailedQuestionCategoryModel[]): void {
    this.questionsNavigation = categories;
  }

  getCategories(): Observable<DetailedQuestionCategoryModel[]> {
    return of(this.questionsNavigation);
  }

  categoriesShouldBeCleared(editedCategoryNumber: number): ClearNextCategories {
    let categoriesList = new ClearNextCategories();
    this.questionsNavigation.forEach(category => {
      if (
        category.group > editedCategoryNumber &&
        (category.isStarted || category.isCompleted)
      ) {
        categoriesList.values.push(category.group);
      }
    });

    return categoriesList;
  }

  groupsShouldBeCleared(editedGroupId: number, answerId: string): ClearNextCategories {
    this.setLastQuestionNavigationState(answerId);
    let groupsList = new ClearNextCategories();
    let categoryIndexOfCurrentGroup: number;
    let currentGroupIndex = 0;
    let groupNumber: number;
    let questionIndex: number;
    let questionDetails = this.getQuestionsBySelectedLanguage().find((question) => question.id === this.getLastQuestionNavigationState().questionId);
    this.questionsNavigation.forEach((category, index) => {
      category.children.forEach((group, childIndex) => {
        if (group.id === editedGroupId) {
          groupNumber = group.group;
          categoryIndexOfCurrentGroup = index;
          currentGroupIndex = childIndex;
          this.clearNextGroups(currentGroupIndex, categoryIndexOfCurrentGroup);
           // Logic for hiding next questions in subcategory
          questionIndex = this.questionsNavigation[categoryIndexOfCurrentGroup].children[childIndex].questions.findIndex(question => question.question.id === questionDetails.id);
          this.questionsNavigation[categoryIndexOfCurrentGroup].children[childIndex].questions[questionIndex].isAnswered = false;
          this.questionsNavigation[categoryIndexOfCurrentGroup].children[childIndex].questions.length = questionIndex + 1;
        }

        if (childIndex > currentGroupIndex && categoryIndexOfCurrentGroup === index && groupNumber) {
          // Logic for reseting all answers
          this.questionsNavigation[categoryIndexOfCurrentGroup].children[childIndex].questions.length = 0;
          this.questionsNavigation[index].children[currentGroupIndex].questions[questionIndex].isAnswered = false;
          group.isStarted = null;
          group.isFinished = null;
        }

        if (index >= categoryIndexOfCurrentGroup && groupNumber) {
          if (groupNumber !== group.group) {
            category.isStarted = null;
            category.isCompleted = null;

            if (!groupsList.values.includes(group.group)) {
              groupsList.values.push(group.group);
            }
          }
        }
      });
    });

    return groupsList;
  }

  escapeRegExp(text: string) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  addNextQuestion(populatedQuestion: PopulatedQuestion | string): void {
    // RequestUserAnswer type is removed, because titleParameters property in Answers.
    let answer: any;
    
    if (typeof populatedQuestion === "object") {

      answer = this.getQuestionsBySelectedLanguage().find(
        question => question.id === populatedQuestion.id
      ).requestAnswerSchema;

      if (
        populatedQuestion.answer !== null &&
        populatedQuestion.answer !== undefined &&
        this.checkIsFirstInitOfQuestion(answer.answerBody)
      ) {
        answer.answerBody = populatedQuestion.answer;
        //Object.keys(answer.answerBody).forEach(prop => {
        //if (populatedQuestion.answer[prop] !== null) {
        //answer.answerBody[prop] = populatedQuestion.answer[prop];

        //}
        //});
      }

      // LOGIC FOR REPLACE PARAMETERS WITH DYNAMIC VALUE SENT BY SERVER
      if (
        populatedQuestion.titleParameters !== null &&
        populatedQuestion.titleParameters !== undefined
      ) {
        let question = this.getQuestionsBySelectedLanguage().find(
          question => question.id === populatedQuestion.id
        );

        populatedQuestion.titleParameters.forEach(param => {
          if (question.title.includes(param.name)) {
            /* question.title = question.title.replace(param.name, param.value); */
            question.title = this.replaceParam(question.title, param.name, param.value);
          }

           // Replace parameters in fields
           question.fieldDefinitions.forEach((field) => {
            if (field.options.label) {
              if (field.options.label.value.toString().includes(param.name)) {
                field.options.label.value = field.options.label.value.toString().replace(param.name, param.value);
              }
            }
          });
        });
      }
    } else {
      answer = this.getQuestionsBySelectedLanguage().find(
        question => question.id === populatedQuestion
      ).requestAnswerSchema;
    }

    this.addAnswer(answer, true, false, true);
  }

  addAnswer(
    answer: ResponseUserAnswer,
    isOnFocus: boolean,
    isAnswered: boolean,
    isCurrent: boolean
  ): void {
    let lastState = this.getLastQuestionNavigationState();
    let question = this.getQuestionsBySelectedLanguage().find(
      question => question.id === answer.id
    );

    if (question !== null && question !== undefined) {

      // LOGIC FOR REPLACE PARAMETERS WITH DYNAMIC VALUE SENT BY SERVER
      if (question.requestAnswerSchema.titleParameters) {
        question.requestAnswerSchema.titleParameters.forEach((param) => {
          // Replace parameters in question
          if (question.title.includes(param.name)) {
            question.title = question.title.replace(param.name, param.value);
          }

          // Replace parameters in fields
          question.fieldDefinitions.forEach((field) => {
            if (field.options.label) {
              if (field.options.label.value.toString().includes(param.name)) {
                field.options.label.value = field.options.label.value.toString().replace(param.name, param.value);
              }
            }
          });
        });
      }
      
      if(answer.titleParameters) {
        answer.titleParameters.forEach((param) => {
          // Replace parameters in question
          if (question.title.includes(param.name)) {
            question.title = question.title.replace(new RegExp(this.escapeRegExp(param.name), 'g'), param.value);
          }

          // Replace parameters in fields
          question.fieldDefinitions.forEach((field) => {
            if (field.options.label) {
              if (field.options.label.value.toString().includes(param.name)) {
                field.options.label.value = field.options.label.value.toString().replace(param.name, param.value);
              }
            }
          });
        });
      }
      this.updateGroupIsStartedStatus(question.category.id);

      if (lastState) {
        if (lastState.groupId !== question.category.id) {
          this.updateGroupIsFinishedStatus(
            lastState.groupId,
            question.category.group
          );
        }
      }

      if (answer.answerBody !== null && answer.answerBody !== undefined) {
        // Fix the next not prepopulated false answers
        this.parseFalseToString(answer);
        
        question.requestAnswerSchema.answerBody = answer.answerBody;
      }

      for (let i = 0; i < this.questionsNavigation.length; i += 1) {
        for (let j = 0; j < this.questionsNavigation[i].children.length; j += 1) {
          if (this.questionsNavigation[i].children[j].id === question.category.id) {
            if (this.answers !== null && this.answers !== undefined) {
              if (this.answers.find(questionItem => questionItem.id === question.id) === undefined) {
                this.answers.push(question);
              } else {
                let answerIndex = this.answers.findIndex(
                  questionItem => questionItem.id === question.id
                );
                Object.keys(question.requestAnswerSchema.answerBody).forEach(
                  prop => {
                    if (question.requestAnswerSchema.answerBody[prop] === null) {
                      question.requestAnswerSchema.answerBody[
                        prop
                      ] = this.answers[
                        answerIndex
                      ].requestAnswerSchema.answerBody[prop];
                    }
                  }
                );

                this.answers[answerIndex] = question;
              }
            }
            this.questionsNavigation[i].children[j].questions.push(
              new CompletedQuestion(question, isOnFocus, isAnswered, isCurrent)
            );
            this.setLastQuestionNavigationState(question.id);

            return;
          }
        }
      }
    }
  }
  
  resetCategoryStatuses(categoryNumber: number): void {
    this.questionsNavigation.forEach(category => {
      category.children.forEach(group => {
        if (group.group === categoryNumber) {
          category.isStarted = false;
          category.isCompleted = false;
          category.isActive = false;
          group.isFinished = false;
          group.isStarted = false;
        }
      });
    });
  }

  updateGroupIsFinishedStatus(lastStateGroupId: number, currentStateCategory: number): void {
    this.questionsNavigation.forEach((category) => {
      category.children.forEach((group) => {
        if (group.id === lastStateGroupId) {
          if (currentStateCategory > category.group || this.shouldUnlockFinalReviewScreen$.getValue()) {
            // Update last category status to completed
            this.updateCategoryCompletedStatus(category.group);
          }
          group.isStarted = false;
          group.isFinished = true;
        }
      });
    });
  }

  //TODO: remove when final screens are dynamic
  addAllGroupsFinishedStatus(): void {
    this.questionsNavigation.forEach((category) => {
      category.children.forEach((group) => {
        // Update last category status to completed
          this.updateCategoryCompletedStatus(category.group);
          group.isStarted = false;
          group.isFinished = true;
      });
    });
  }

  updateCategoryCompletedStatus(groupNumber: number): void {
    let category = null;
    for (let i = 0; i < this.questionsNavigation.length; i += 1) {
      for (let j = 0; j < this.questionsNavigation[i].children.length; j += 1) {
        if (this.questionsNavigation[i].children[j].parent.group === groupNumber) {
          category = this.questionsNavigation[i];
          break;
        }
      }
      if (category !== null) {
        break;
      }
    }

    if (category !== null) {
      category.isStarted = false;
      category.isCompleted = true;
    }
  }

  updateGroupIsStartedStatus(groupId: number): void {
    this.questionsNavigation.forEach(category => {
      category.children.forEach(group => {
        //TODO: remove when final screens are dynamic !this.shouldUnlockFinalDocumentsScreen$.getValue() , !this.shouldUnlockFinalReviewScreen$.getValue()
        if (group.id === groupId && !this.shouldUnlockFinalDocumentsScreen$.getValue()  && !this.shouldUnlockFinalReviewScreen$.getValue()) { 

          category.isCompleted = false;
          category.isStarted = true;

          group.isStarted = true;
          group.isFinished = false;
        }
      });
    });

    this.castCategories$.next(of(this.questionsNavigation));
  }

  getQuestionsByGroup(groupId: number): void {
    this._displayedGroup = groupId;
    let groupNumber : number;
    let categoryIndexOfCurrentGroup: number;
    let currentGroupIndex: number;
    this.questionsNavigation.forEach((category, index) => {
      category.children.forEach((group, childIndex) => {
        if (group.id === groupId) {
          groupNumber = group.group;
          categoryIndexOfCurrentGroup = index;
          currentGroupIndex = childIndex;
          // Checking for all answered questions in selected group
          let questionIndex = this.questionsNavigation[categoryIndexOfCurrentGroup].children[childIndex].questions.filter(question => question.isAnswered === true);
          // Changing the focus status to false for all answered question in the group
           questionIndex.forEach(question => {
            if(question.isOnFocus){
              question.isOnFocus = false;
            }
          })
        }
      });
    });
    
    this.questionsNavigation.forEach(category => {
      category.children.forEach(group => {
        if (group.id === groupId) {
          this.castActiveGroup$.next({
            name: group.name,
            isStarted: group.isStarted,
            isFinished: group.isFinished
          });
          this.cast$.next(group.questions);
        }
      });
    });
  }

  getPrevGroupQuestions(): void {
    let activeGroupIndex = null;
    let activeCategoryIndex = null;

    for (let i = 0; i < this.questionsNavigation.length; i += 1) {
      activeCategoryIndex = i;
      for (let j = 0; j < this.questionsNavigation[i].children.length; j += 1) {
        if (
          this.questionsNavigation[i].children[j].id === this._displayedGroup
        ) {
          activeGroupIndex = j;
          break;
        }
      }
      if (activeGroupIndex !== null) {
        break;
      }
    }

    // Select last finished group

    for (
      let i = JSON.parse(JSON.stringify(activeCategoryIndex));
      i >= 0;
      i -= 1
    ) {
      if (i === activeCategoryIndex) {
        for (let j = activeGroupIndex - 1; j >= 0; j -= 1) {
          if (
            this.questionsNavigation[activeCategoryIndex].children[j]
              .isFinished ||
            this.questionsNavigation[activeCategoryIndex].children[j].isStarted
          ) {
            this.getQuestionsByGroup(
              this.questionsNavigation[activeCategoryIndex].children[j].id
            );
            return;
          }
        }
      } else {
        for (
          let j = this.questionsNavigation[i].children.length - 1;
          j >= 0;
          j -= 1
        ) {
          if (
            this.questionsNavigation[i].children[j].isFinished ||
            this.questionsNavigation[i].children[j].isStarted
          ) {
            this.getQuestionsByGroup(
              this.questionsNavigation[i].children[j].id
            );
            return;
          }
        }
      }
    }
  }

  getNextGroupQuestions(): void {
    let activeGroupIndex = null;
    let activeCategoryIndex = null;
    for (let i = 0; i < this.questionsNavigation.length; i += 1) {
      activeCategoryIndex = i;
      for (let j = 0; j < this.questionsNavigation[i].children.length; j += 1) {
        if (this.questionsNavigation[i].children[j].id === this._displayedGroup) {
          activeGroupIndex = j;
          break;
        }
      }
      if (activeGroupIndex !== null) {
        break;
      }
    }

    // Select next group if exist

    for (let i = JSON.parse(JSON.stringify(activeCategoryIndex)); i < this.questionsNavigation.length; i += 1) {
      if (i === activeCategoryIndex) {
        for (let j = activeGroupIndex + 1; j < this.questionsNavigation[activeCategoryIndex].children.length - 1; j += 1) {
          if (this.questionsNavigation[activeCategoryIndex].children[j].isStarted || this.questionsNavigation[activeCategoryIndex].children[j].isFinished) {
            this.getQuestionsByGroup(this.questionsNavigation[activeCategoryIndex].children[j].id);
            return;
          }
        }
      } else {
        for (let j = 0; j < this.questionsNavigation[i].children.length; j += 1) {
          if (this.questionsNavigation[i].children[j].isStarted || this.questionsNavigation[i].children[j].isFinished) {
            this.getQuestionsByGroup(this.questionsNavigation[i].children[j].id);
            return;
          }
        }
      }
    }
  }

  editQuestionAnswer(questionId: string): void {
    // this.setLastQuestionNavigationState(questionId);

    this.setEditMode(true);
    let questionDetails = this.getQuestionsBySelectedLanguage().find((question) => question.id === questionId);

    if (questionDetails !== null && questionDetails !== undefined) {
      let questionCategoryIndex = null;
      let questionGroupIndex = null;

      for (let i = 0; i < this.questionsNavigation.length; i += 1) {
        for (let j = 0; j < this.questionsNavigation[i].children.length; j += 1) {
          if (this.questionsNavigation[i].children[j].id === questionDetails.category.id) {
            questionCategoryIndex = i;
            questionGroupIndex = j;
            break;
          }
        }

        if (questionCategoryIndex !== null) {
          break;
        }
      }

      if (questionCategoryIndex !== null && questionCategoryIndex !== undefined && questionCategoryIndex > -1) {
        if (questionGroupIndex !== null && questionGroupIndex !== undefined && questionGroupIndex > -1) {
          for (let i = 0; i < this.questionsNavigation.length; i += 1) {
            if (i === questionCategoryIndex) {
              for (let j = 0; j < this.questionsNavigation[i].children.length; j += 1) {
                let questionIndex = this.questionsNavigation[questionCategoryIndex].children[j].questions.findIndex(question => question.question.id === questionDetails.id);

                if (questionIndex !== null && questionIndex !== undefined && questionIndex > -1) {
                  this.questionsNavigation[questionCategoryIndex].children[j].questions[questionIndex].isOnFocus = true;
                  // this.questionsNavigation[questionCategoryIndex].children[j].questions[questionIndex].isAnswered = false;

                  // this.questionsNavigation[questionCategoryIndex].children[j].isStarted = true;
                  // this.questionsNavigation[questionCategoryIndex].children[j].isFinished = false;

                  // this.questionsNavigation[questionCategoryIndex].isCompleted = false;
                  // this.questionsNavigation[questionCategoryIndex].isStarted = true;
                  // this.questionsNavigation[questionCategoryIndex].children[j].questions.length = questionIndex + 1;

                  //Pass Stacy message (Simple question)
                  if (
                    questionDetails.fieldDefinitions &&
                    questionDetails.fieldDefinitions.length === 1 &&
                    questionDetails.fieldDefinitions[0].options.stacyGeneral !==
                    null &&
                    questionDetails.fieldDefinitions[0].options.stacyGeneral !==
                    undefined &&
                    questionDetails.fieldDefinitions[0].options
                      .stacyAdditionalIfUserPausesForXTime !== null &&
                    questionDetails.fieldDefinitions[0].options
                      .stacyAdditionalIfUserPausesForXTime !== undefined &&
                    questionDetails.fieldDefinitions[0].options.stacyTimeOut !==
                    null &&
                    questionDetails.fieldDefinitions[0].options.stacyTimeOut !==
                    undefined
                  ) {
                    this.castStacy$.next(
                      new StacyMessagesModel(
                        questionDetails.fieldDefinitions[0].options.stacyGeneral.value.toString(),
                        questionDetails.fieldDefinitions[0].options.stacyAdditionalIfUserPausesForXTime.value.toString(),
                        Number(questionDetails.fieldDefinitions[0].options.stacyTimeOut.value)
                      )
                    );
                  }

                  // this.clearNextGroups(questionGroupIndex, questionCategoryIndex);

                  // this.getQuestionsByGroup(this.questionsNavigation[questionCategoryIndex].children[j].id);
                }
                // else if (j > questionGroupIndex) {
                //   this.questionsNavigation[questionCategoryIndex].children[j].questions.length = 0;
                // }
              }
            }
          }
        }
      }
    }
  }

  private clearNextGroups(groupIndex: number, categoryIndex: number): void {
    this.questionsNavigation.forEach((category, index) => {
      if (index === categoryIndex) {
        category.children.forEach((group, grIndex) => {
          if (grIndex > groupIndex) {
            group.isFinished = false;
            group.isStarted = false;
          }
        })
      }
      if (index > categoryIndex) {
        category.children.forEach((group) => {
          group.isStarted = false;
          group.isFinished = false;
        })
      }
    })
  }

  updateCompletedQuestionOnFocusState(questionId: string): void {
    try {
      let question = this.getQuestionsBySelectedLanguage().find(
        question => question.id === questionId
      );
      let category;
      for (let i = 0; i < this.questionsNavigation.length; i += 1) {
        for (
          let j = 0;
          j < this.questionsNavigation[i].children.length;
          j += 1
        ) {
          if (
            this.questionsNavigation[i].children[j].id === question.category.id
          ) {
            category = this.questionsNavigation[i];
            break;
          }
        }

        if (category !== null && category !== undefined) {
          break;
        }
      }
      let group = category.children.find(
        group => group.id === question.category.id
      );

      let questionToUpdate = group.questions.find(
        question => question.question.id === questionId
      );

      questionToUpdate.isOnFocus = !questionToUpdate.isOnFocus;
      questionToUpdate.isAnswered = !questionToUpdate.isAnswered;
    } catch (error) {
      console.log("Error catched>>>", error.name);
      console.log("QUESTION_ID>>>", questionId);
      console.log("QUESTIONS>>>", this.getQuestionsBySelectedLanguage());
      console.log("GROUP>>>", this.questionsNavigation);
    }
  }

  setAreAnswersLoaded(areAnswerLoaded: boolean): void {
    this._areAnswerLoaded = areAnswerLoaded;
  }

  getAreAnswersLoaded(): boolean {
    return this._areAnswerLoaded;
  }

  clearCompletedQuestions(): void {
    if (
      this.questionsNavigation !== null &&
      this.questionsNavigation !== undefined
    ) {
      this.questionsNavigation.forEach(category => {
        category.children.forEach(group => {
          group.questions = [];
        });
      });
    }
  }

  getAnswerByQuestionId(questionId: string): any {
    let elemIndex = null;
    if (
      this.questionsNavigation !== null &&
      this.questionsNavigation !== undefined
    ) {
      for (let i = 0; i < this.questionsNavigation.length; i += 1) {
        for (
          let j = 0;
          j < this.questionsNavigation[i].children.length;
          j += 1
        ) {
          elemIndex = this.questionsNavigation[i].children[
            j
          ].questions.findIndex(
            question => question.question.id === questionId
          );

          if (elemIndex !== null && elemIndex !== undefined && elemIndex >= 0) {
            return this.questionsNavigation[i].children[j].questions[elemIndex]
              .question.requestAnswerSchema.answerBody;
          }
        }
      }
    }

    return elemIndex;
  }

  getQuestionById(questionId: string): ComplexQuestion {
    if (this.getQuestionsBySelectedLanguage() !== null) {
      return this.getQuestionsBySelectedLanguage().find(
        question => question.id === questionId
      );
    }
    return null;
  }

  clearStore(): void {
    this.questions = null;
    this.questionsNavigation = null;
    this.answers = null;
    this.cast$.next(null);
    this.castCategories$.next(null);
    this.castStacy$.next(null);
    this.castActiveGroup$.next(null);
    this.setLastQuestionNavigationState(null);
  }

  private parseFalseToString(answer: ResponseUserAnswer): void {
    if(answer.answerBody.value === false) {
      answer.answerBody.value = "false"
    }else if(answer.answerBody.values !== undefined && answer.answerBody.values !== null) {
      answer.answerBody.values.forEach(item => {
        for (var key in item) {
          if(item[key] === false) {
            item[key] = "false"
          }
        }
      });
    }
  }

  private replaceParam(title: string, param: string, value: string): string {
    // Replace occurrences of the parameter with the value until the title contains the parameter
    while (title.includes(param)) {
        title = title.replace(param, value);
    }
    return title;
  }

  private checkIsFirstInitOfQuestion(answerBody: object): boolean {
    let isFirstInit = true;

    for (let key in answerBody) {
      if(answerBody[key] !== null) {

        return false;
      }  
    }

    return isFirstInit;
  }
}
