import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/shared-services/other-services/auth/auth.service';

import { DEFAULT_THEME, THEME_KEY } from './consts/consts';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthService, public translate: TranslateService) {
    translate.addLangs(['en-US', 'es']);
    translate.setDefaultLang('en-US');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es/) ? browserLang : 'en-US');
  }

  ngOnInit(): void {
    let theme = '';
    if (this.authService.isAuthenticated()) {
      theme = localStorage.getItem(THEME_KEY);

      if (theme === null || theme === undefined || theme === '') {
        theme = DEFAULT_THEME;
      }
    }
    else {
      localStorage.removeItem(THEME_KEY);

      theme = DEFAULT_THEME;
    }
    document.body.classList.remove('dark', 'light');
    document.body.classList.add(theme);
  }
}
