<div class="residency-offline">
    <div class="box">
        <p class="stacy"><img src="../../../../../../assets/img/avatar-stacy2.png" alt="stacy"></p>
        <p>Filing status
                You selected the filing status, “married filing joint”. Your tax return should be prepared offline
                as
                Sprintax doesn’t support this option.
                Our competitive prices start at 90 USD. We’ll review your case and our tax experts will contact you
                with
                more information and a price.
            </p>
        <button class="btn btn-primary" (click)="submit()">Back</button>
    </div>
</div>