<section class="offline">
    <ng-container [ngSwitch]="state">
      <!-- non-residency-finish Canada TDS -->
      <app-massachusetts-review-two *ngSwitchCase="status.MessageForResidencyFinishWithNonResident" (action)="onActionEvent()"></app-massachusetts-review-two>
      <app-massachusetts-review-one *ngSwitchCase="status.MessageForResidencyFinishWithResident" (action)="onActionEvent()"></app-massachusetts-review-one>
      <app-massachusetts-review-three *ngSwitchCase="status.MessageForResidencyFinishWithEmigrant" (action)="onActionEvent()"></app-massachusetts-review-three>
      <app-massachusetts-review-four *ngSwitchCase="status.MessageForResidencyFinishWithImmigrant" (action)="onActionEvent()"></app-massachusetts-review-four>
      
      <app-about-you *ngSwitchCase="status.GettingToKnowYou" (action)="onActionEvent()"></app-about-you>
      <app-income-document *ngSwitchCase="status.LetsTalkMoney" (action)="onActionEvent()"></app-income-document>
      <app-offset-your-expenses *ngSwitchCase="status.OffsetYourExpenses" (action)="onActionEvent()"></app-offset-your-expenses>
      <app-living-in-us-finish *ngSwitchCase="status.StateTaxes" (action)="onActionEvent()"></app-living-in-us-finish>
      <app-state *ngSwitchCase="status.StateFinished" (action)="onActionEvent()"></app-state>

    </ng-container>
  </section>
