export enum ResponseCode {
  // Finished Events
  IncomeRecoveriesFinished = 238,
  PersonalDetailsFinished = 211,
  StateTaxesFinished = 214,
  NonResident = 219,
  OffsetYourExpensesFinished = 222,
  StateFinished = 230,

  // Finished with offline message
  Resident = 209,
  NotSupportedVisa = 216,
  ResidentTurboTax = 217,
  OfflineStateEvent = 221,
  ResidencyOfflineStateEvent = 220,
  MarriedFilingJointEvent = 225,
  NoUsIncomeStatusCode = 231,
  DifferentServiceTypeStatusCode = 232,
  MessageForResidencyFinishWithResident = 234,
  MessageForResidencyFinishWithNonResident = 235,
  MessageForResidencyFinishWithEmigrant = 236,
  MessageForResidencyFinishWithImmigrant = 237,

  // Initialize
  StateInitialization = 212,
  AnswerQuestions = 213,
  
  // Skip
  Skip = 223
}
