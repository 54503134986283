import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseUrlModel } from './models/base-url.model';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentStoreService {
  private baseUrlModel: BaseUrlModel;
  private _shouldShowWelcomeMessage: boolean;
  private isChangeBaseUrl: boolean = false
  private isTestEnv: boolean;
  private ssoUrl: string;

  showWelcomeMessage$ = new BehaviorSubject<boolean>(true);
  shouldShowEditBtns$;
  localServerUrl: string;
  dashboardBaseUrl;
  dashboardBasePort;
  dashborTaxYear;
  stripeApiKey: string;
  
  constructor() {
    /* AWS environment */
    this.baseUrlModel = new BaseUrlModel();
    this.baseUrlModel.protocol = 'https';
    this.baseUrlModel.url = 'api-taxdocuments.sprintax.ca';
    this.baseUrlModel.paymentServerUrl = '192.168.14.94';
    this.baseUrlModel.port = 443;
    this.baseUrlModel.apiVersion = 1;
    
    this.ssoUrl = 'https://sso.sprintax.com';
    this.isTestEnv = false;
    this._shouldShowWelcomeMessage = true;
    this.shouldShowEditBtns$ = new BehaviorSubject<boolean>(false);
    this.localServerUrl = 'https://www.sprintax.ca/docs/'; 
    this.stripeApiKey = 'pk_live_51IIyAhEihcEnyOKYykT4V9Y7xZD9g9q3LT8QVVDfpn3pu8VqlI6uYGgiueVuykPyJQSeH0dJA4B7Ks64Ox5O6oww00coDQ6jby';

    /* DEV environment */
    /* this.baseUrlModel = new BaseUrlModel();
    this.baseUrlModel.protocol = 'http';
    this.baseUrlModel.url = '192.168.14.95';
    this.baseUrlModel.paymentServerUrl = '192.168.14.94';
    this.baseUrlModel.port = 52024; //50060 -> 2020, 52021 -> 2021, 52022 -> 2022, 52023 -> 2023
    this.baseUrlModel.apiVersion = 1;

    this.ssoUrl = 'https://sso-test.taxback.com'
    this.isTestEnv = true;
    this._shouldShowWelcomeMessage = true;
    this.localServerUrl = 'http://192.168.4.64/tdsCan/';
    this.shouldShowEditBtns$ = new BehaviorSubject<boolean>(false);
    this.stripeApiKey = 'pk_test_51Hr0rbDy1VlXRHk3vwuKkshcDBXj8ip7QIKoYzGwLMDihZFFezC7QXV8gvWDEhLmsA122JToW7RABsj55Edl9evw00cqCRZYlG'; */
  }

  getStripeApiKey(): string {

    return this.stripeApiKey;
  }

  setServerInstancePort(port: number): void {
    if(!this.isChangeBaseUrl) {
      this.baseUrlModel.port = port;
    }
  }

  setServerInstanceProtocol(protocol: string): void {
    if(!this.isChangeBaseUrl) {
      this.baseUrlModel.protocol = protocol.replace(/[^a-zA-Z]+/g, '');
    }
  }

  setServerInstanceUrl(url: string): void {
    if(!this.isChangeBaseUrl) {
      this.baseUrlModel.url = url;
    }
  }

  updateUrl(urlModel: BaseUrlModel): void {
    this.isChangeBaseUrl = true;
    this.baseUrlModel = urlModel;
  }

  /**
  * For BE test env config only
  */
  updateBeDashboardBaseUrlAndPort(url, port): void {
    this.dashboardBaseUrl = url;
    this.dashboardBasePort = port;
  }

  updateBeDashboardTaxYear(taxYear): void {
    this.dashborTaxYear = taxYear;
  }

  getBeDashboardTaxYear() {
    return this.dashborTaxYear;
  }

  getBEDashboardBaseUrl(): string {

    return this.dashboardBaseUrl;
  }

  getBEDashboardPort(): string {

    return this.dashboardBasePort;
  }

  getBaseUrl(): BaseUrlModel {
    return this.baseUrlModel;
  }

  getSsoUrl(): string {
    return this.ssoUrl;
  }

  getDashboardGetBaseUrl(): BaseUrlModel {
    let baseUrlModel = new BaseUrlModel();
    baseUrlModel.paymentServerUrl = '192.168.14.94';
    baseUrlModel.apiVersion = 1;

    if(this.isTestEnv) {
      baseUrlModel.protocol = 'http';
      if(this.dashboardBaseUrl !== undefined && this.dashboardBasePort !== undefined) {
        baseUrlModel.url = this.dashboardBaseUrl;
        baseUrlModel.port = this.dashboardBasePort;
      }else {
        baseUrlModel.url = '192.168.14.95';
        baseUrlModel.port = 52024; //50060 -> 2020, 52021 -> 2021, 52022 -> 2022, 52023 -> 2023
      }
    }else {
      baseUrlModel.protocol = 'https';
      baseUrlModel.url = 'api-taxdocuments.sprintax.ca';
      baseUrlModel.port = 443;
    }

    return baseUrlModel;
  }

  getLocalServerUrl(): string {
    return this.localServerUrl;
  }

  hideWelcomeMessage(): void {
    this._shouldShowWelcomeMessage = false;
  }

  get shouldShowWelcomeMessage(): boolean {
    return this._shouldShowWelcomeMessage;
  }

  // Using to reset user environment settings after application exit
  resetEnvironmentSettings(): void {
    this._shouldShowWelcomeMessage = true;
  }
}
