<div class="residency-offline">
  <div class="box">
    <p class="stacy"><img src="../../../../../../assets/img/avatar-stacy2.png" alt="stacy"></p>
    <h4>Hi there,</h4>
    <p>Based on the information you provided, you are deemed a <a href="#" class="tip" data-toggle="modal"
        data-target="#usresidentModal">US resident for tax purposes</a>, which means Sprintax software isn’t suitable
      for you. Sprintax facilitates non-resident tax return preparation only.</p>
    <p>However, our partner TurboTax specializes in tax returns for US residents and you can click <a
        href="https://turbotax.intuit.com/microsite/home.htm?priorityCode=5631500000&cid=all_taxbac_aff_5631500000">here</a>
      to proceed. By pressing "Back", you can edit any information you've entered so far.</p>
    <button class="btn btn-primary" (click)="submit()">Back</button>
  </div>
</div>

<div class="modal fade" id="usresidentModal" tabindex="-1" role="dialog" aria-labelledby="usresidentModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">Why am I a US Resident for tax purposes?</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>There are many factors that are considered when determining your residency status.</p>
        <p>1. If you answered "Yes" to being a US. citizen or have been a green card holder or if you have applied for
          US citizenship/ lawful residence then you will be deemed a US resident for tax purposes.</p>
        <p>2. Next we add up all the days that you have been physically present in the US during the tax year.</p>
        <p>- PLUS 1/3 of the number of days you were physically present in the US during the first preceding year.</p>
        <p>- PLUS 1/6 the number of days you were physically present in the US during the second preceding year.</p>
        <p>If the number of days for these three years equals 183 days or greater, then you are considered a US resident
          for tax purposes.</p>
        <p>In addition to this we also take your visa type into account. Depending on your visa type you can be exempt
          for certain years and so these exempt years are excluded from this calculation.</p>
        <p><a href="https://www.irs.gov/individuals/international-taxpayers/substantial-presence-test">Read more</a></p>
      </div>
    </div>
  </div>
</div>